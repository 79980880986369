import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Text } from '@wtag/rcl-typography';
import Icon from '@wtag/rcl-icon';
import './matrix-step.styles.scss';

const MatrixStep = props => {
  const { title, index, activeStepIndex, isDone, isLastStep, isFirstStep } = props;
  const isActive = index === activeStepIndex;
  const isFerstStep = (index === 0) || isFirstStep;

  return (
    <div className="matrix-step">
      <div className="matrix-step__indicator">
        <div
          className={classNames('matrix-step__rails', {
            'matrix-step__rails--hidden': isFerstStep,
          })}
        />
        <div
          className={classNames('matrix-step__circle', {
            'matrix-step__circle--active': isActive,
            'matrix-step__circle--done': isDone,
          })}
        >
          {isDone ? (
            <Icon name="check" color="neutral" />
          ) : (
            <Text level={2} weight="normal">
              {index + 1}
            </Text>
          )}
        </div>
        <div
          className={classNames('matrix-step__rails', {
            'matrix-step__rails--hidden': isLastStep,
          })}
        />
      </div>
      <div
        className={classNames('matrix-step__title', {
          'matrix-step__title--active': isActive,
        })}
      >
        <Text level={2} weight="normal">
          {title}
        </Text>
      </div>
    </div>
  );
};

MatrixStep.defaultProps = {
  title: null,
  isDone: false,
  isLastStep: false,
  isFirstStep: false,
};

MatrixStep.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  index: PropTypes.number.isRequired,
  activeStepIndex: PropTypes.number.isRequired,
  isDone: PropTypes.bool,
  isLastStep: PropTypes.bool,
  isFirstStep: PropTypes.bool,
};

export default MatrixStep;
