import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import Card from '@wtag/rcl-card';
import Input from '@wtag/rcl-input';
import SelectBox from '@wtag/rcl-select-box';
import Button from '@wtag/rcl-button';
import { CheckBox, I18nText, PhoneNumber, CountryItem } from '@wtag/react-comp-lib';
import Alert from 'sharedWebpack/Alert';
import DatePickerWithFocusedStateWrapper from 'sharedWebpack/DatePickerWithFocusedStateWrapper';
import { connect } from 'react-redux';
import namePrefixBuilder from '../../../Search/flight/helpers/namePrefixBuilder';
import CountrySelector from '../../../components/CountrySelector';
import findLabelValue from '../../../helpers/findLabelValue';
import { customerSelected } from '../../../actions/flight';

class TravelerInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addIndividualContactInformation:
        this.props.fields.contactInformationCopied.value && this.props.fields.id.value,
      previousFormData: {
        ...this.props.fields,
        residenceAddress: { ...this.props.fields.residenceAddress },
        address: { ...this.props.fields.address },
        splitPrimaryPhoneNumber: { ...this.props.fields.splitPrimaryPhoneNumber },
      },
    };

    this.NAME_PREFIX = namePrefixBuilder();
  }

  onPhoneOrCountryChange = async (countryCode, phoneNumber) => {
    const {
      fields: { phone },
      selectedCustomer,
      onCustomerSelected,
      index,
    } = this.props;

    const phoneNumberWithCountryCode = `+${countryCode}${phoneNumber}`;

    phone.onChange(phoneNumberWithCountryCode);

    const updatedSelectedCustomer = {
      ...selectedCustomer,
      phone: phoneNumberWithCountryCode,
    };
    await onCustomerSelected(index, updatedSelectedCustomer);
  };

  onNamePrefixChange = (value, namePrefixField) => {
    const {
      fields: { namePrefix },
      selectedCustomer,
      onCustomerSelected,
      index,
    } = this.props;

    namePrefix.onChange(value);

    namePrefixField.onChange(value);

    const updatedSelectedCustomer = {
      ...selectedCustomer,
      namePrefix: value,
    };
    onCustomerSelected(index, updatedSelectedCustomer);
  };

  setAddIndividualContactInformation = async event => {
    if (!this.props.fields.id.value) {
      const copyableFields = this.segregatedContactInformation(this.props.fields);
      Object.keys(copyableFields).forEach(key => {
        if (copyableFields[key] && typeof copyableFields[key].value !== 'undefined') {
          copyableFields[key].onChange('');
        }
      });
    }

    const { selectedCustomer, onCustomerSelected, index } = this.props;
    const { checked: value } = event.target;

    const updatedSelectedCustomer = {
      ...selectedCustomer,
      email: '',
      phone: '',
      travelArrangerEmail: '',
      isTravelArrangerEmailUsed: '',
      residenceAddress: {
        street: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      },
      address: {
        street: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      },
      contactInformationCopied: !value,
    };

    await onCustomerSelected(index, updatedSelectedCustomer);
    this.props.fields.contactInformationCopied.onChange(!value);
    this.setState({ addIndividualContactInformation: !this.state.addIndividualContactInformation });
  };

  phoneCountryCodes = this.props.countryCodes.map(([name, phoneCode]) => ({
    label: <CountryItem countryName={name} countryCode={`+${phoneCode}`} />,
    value: phoneCode,
  }));

  segregatedContactInformation = fields => {
    const {
      email,
      phone,
      travelArrangerEmail,
      isTravelArrangerEmailUsed,
      residenceAddress,
    } = fields;

    return { email, phone, travelArrangerEmail, isTravelArrangerEmailUsed, ...residenceAddress };
  };

  addSpaceBetweenError = field => {
    const { error } = field;
    if (error) {
      const initialValue = [];
      const errorMessages = error.reduce((accumulator, currrentValue) => {
        accumulator.push(currrentValue);
        return accumulator;
      }, initialValue);

      return { ...field, error: errorMessages };
    }

    return field;
  };

  minBirthDate = () => {
    const date = new Date();
    const newDate = new Date('01');

    switch (this.props.fields.type.value) {
      case 'adult':
        return null;

      case 'child':
        if (this.props.flightPresent) {
          newDate.setFullYear(date.getFullYear() - 11);
        } else {
          newDate.setFullYear(date.getFullYear() - 16);
        }

        return newDate;

      case 'infant':
        newDate.setFullYear(date.getFullYear() - 2);
        return newDate;

      default:
        return null;
    }
  };

  copyFirstTravelerContactInformation = async () => {
    const { selectedCustomer, onCustomerSelected, index } = this.props;

    const copyableContactInformation = this.segregatedContactInformation(this.props.fields);

    const firstTravelerContactInformation = this.segregatedContactInformation(
      this.props.templateTraveler,
    );

    const updatedSelectedCustomer = {
      ...selectedCustomer,
      email: firstTravelerContactInformation.email.value,
      phone: firstTravelerContactInformation.phone.value,
      travelArrangerEmail: firstTravelerContactInformation.travelArrangerEmail.value,
      isTravelArrangerEmailUsed: firstTravelerContactInformation.isTravelArrangerEmailUsed.value,
      residenceAddress: {
        street: firstTravelerContactInformation.street.value,
        city: firstTravelerContactInformation.city.value,
        state: firstTravelerContactInformation.state.value,
        zip: firstTravelerContactInformation.zip.value,
        country: firstTravelerContactInformation.country.value,
      },
      address: {
        street: firstTravelerContactInformation.street.value,
        city: firstTravelerContactInformation.city.value,
        state: firstTravelerContactInformation.state.value,
        zip: firstTravelerContactInformation.zip.value,
        country: firstTravelerContactInformation.country.value,
      },
      contactInformationCopied: true,
    };

    await onCustomerSelected(index, updatedSelectedCustomer);

    Object.keys(copyableContactInformation).forEach(key => {
      if (
        copyableContactInformation[key] &&
        firstTravelerContactInformation[key] &&
        typeof firstTravelerContactInformation[key].value !== 'undefined'
      ) {
        copyableContactInformation[key].onChange(firstTravelerContactInformation[key].value);
      }
    });

    this.props.fields.contactInformationCopied.onChange(true);
  };

  travelersHasEmailErrors = async () => {
    const { fields, travelerEmailValidate, index } = this.props;
    const { email, guestUser } = fields;

    if (this.context.laymanMode) {
      if (email.valid) await travelerEmailValidate();
      if (this.state.addIndividualContactInformation || guestUser.value || index === 0)
        return this.props.fields.email.invalid;
    }

    return false;
  };

  travelersHasPhoneErrors = async () => {
    await this.props.phoneNumberValidate();
    return !this.props.fields.phone?.valid;
  };

  proceedToTravelInformation = async () => {
    if ((await this.travelersHasEmailErrors()) || (await this.travelersHasPhoneErrors())) return;

    if (this.props.index !== 0 && !this.state.addIndividualContactInformation) {
      this.copyFirstTravelerContactInformation();
    }
    this.props.setNewTravelerFormPage('travel_information');
  };

  saveTraveler = async () => {
    if ((await this.travelersHasEmailErrors()) || (await this.travelersHasPhoneErrors())) return;

    if (this.props.index !== 0 && !this.state.addIndividualContactInformation) {
      this.copyFirstTravelerContactInformation();
    }

    if (this.props.fields.guestUser.value) {
      this.props.fields.guestUser.onChange('yes');
    } else {
      this.props.fields.newUser.onChange('yes');
    }
    if (this.props.isEditForm) {
      this.props.setSingleTravelerShowPage('show');
    } else if (this.props.flightPresent) {
      this.props.setNewTravelerFormPage('travel_information');
    }
  };

  resetFormChange = () => {
    const { previousFormData } = this.state;
    const formFields = this.props.fields;

    Object.keys(formFields).forEach(key => {
      const isFieldValid = formFields[key] && 'value' in formFields[key];
      const objectKeyMapper = ['residenceAddress', 'splitPrimaryPhoneNumber'];

      if (objectKeyMapper.includes(key)) {
        const fields = previousFormData[key];
        Object.keys(fields).forEach(field => {
          fields[field].onChange(previousFormData[key][field].value);
        });
      } else if (isFieldValid) {
        formFields[key].onChange(previousFormData[key].value);
      }
    });
  };

  resetTraveler = () => {
    if (this.props.isEditForm) {
      this.resetFormChange();
      this.props.setSingleTravelerShowPage('show');
    } else {
      this.removeData();
      this.props.setNewTravelerFormPage('search');
    }
  };

  removeData = () => {
    Object.keys(this.props.fields).forEach(key => {
      if (
        this.props.fields[key] &&
        key !== 'type' &&
        key !== 'guestUser' &&
        key !== 'itemChannels' &&
        typeof this.props.fields[key].value !== 'undefined'
      ) {
        this.props.fields[key].onChange('');
      }
    });
    this.props.reset(this.props.index);
  };

  maxBirthDate = () => {
    const date = new Date();
    const newDate = new Date('01');

    switch (this.props.fields.type.value) {
      case 'adult':
        newDate.setFullYear(date.getFullYear() - 12);
        return newDate;

      case 'child':
        if (this.props.flightPresent) {
          newDate.setFullYear(date.getFullYear() - 2);
          return newDate;
        }
        return date;

      case 'infant':
        return date;

      default:
        return null;
    }
  };

  renderSalutation = namePrefixField => (
    <div className="col-md-6 col-lg-6 col-12 traveler-information__container-field">
      <SelectBox
        {...namePrefixField}
        options={this.NAME_PREFIX}
        label={I18n.t('components.ibe.traveler_details.form.fields.title')}
        onChange={selectedOption =>
          selectedOption && this.onNamePrefixChange(selectedOption.value, namePrefixField)
        }
        value={findLabelValue(this.NAME_PREFIX, namePrefixField.value)}
        isDisabled={this.props.disabled}
        touched={this.props.touched}
        errorMsg={namePrefixField.error}
        size="tiny"
        width="full"
        placeholderText={I18n.t('components.ibe.traveler_details.form.placeholders.title')}
        required={true}
        isClearable={false}
      />
    </div>
  );

  renderUserTravelArrangerEmailCheckbox = () => {
    const {
      index,
      fields: { guestUser },
      selectedCustomer,
      onCustomerSelected,
    } = this.props;
    const { travelArrangerDefaultEmail } = this.context;

    const handleTravelArrangerCheckboxClick = isTravelArrangerEmailUsed => {
      const updatedSelectedCustomer = {
        ...selectedCustomer,
        isTravelArrangerEmailUsed,
      };

      this.props.fields.isTravelArrangerEmailUsed.onChange(isTravelArrangerEmailUsed);

      if (isTravelArrangerEmailUsed) {
        updatedSelectedCustomer.travelArrangerEmail = travelArrangerDefaultEmail;

        this.props.fields.travelArrangerEmail.onChange(this.context.travelArrangerDefaultEmail);
        this.props.fields.email.onChange();
      }

      onCustomerSelected(index, updatedSelectedCustomer);
    };

    if (guestUser.value && this.props.editable) {
      return (
        <CheckBox
          label={<I18nText id="ibe.cart.use_arranger_email" returnStringOnly={true} />}
          className="checkbox-gap-top"
          checked={this.props.fields.isTravelArrangerEmailUsed.value}
          onChange={event => handleTravelArrangerCheckboxClick(event.target.checked)}
          size="small"
        />
      );
    }

    return null;
  };

  renderPhoneCheckBox = () => {
    if (this.props.index === 0 && this.props.flightPresent) {
      return (
        <div className="col-12 col-bleed traveler-information__container-field-checkbox">
          <CheckBox
            label={<I18nText id="ibe.cart.pass_phone_to_airline" returnStringOnly={true} />}
            checked={this.props.passPhoneToAirline}
            onChange={event => this.props.setResoOptionPhone(event.target.checked)}
            size="small"
          />
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      fields: {
        namePrefix,
        firstName,
        middleName,
        lastName,
        birthday,
        email,
        travelArrangerEmail,
        isTravelArrangerEmailUsed,
        phone,
        adjustProfileData,
        associateOrganization,
        residenceAddress: { street, city, zip, state, country },
        splitPrimaryPhoneNumber: { countryCode, number },
      },
      disabled,
      touched,
      flightPresent,
      isAssociatedToOrganization,
      showHintForTravelerArrangerEmail,
      index,
      isEditForm,
      onCustomerSelected,
      selectedCustomer,
    } = this.props;

    const showContactInformation = index === 0 || this.state.addIndividualContactInformation;
    const travelerCartEmail = isTravelArrangerEmailUsed.value ? travelArrangerEmail : email;

    const onBirthDayChange = value => {
      birthday.onChange(value);

      const updatedSelectedCustomer = {
        ...selectedCustomer,
        birthday: value,
      };
      onCustomerSelected(index, updatedSelectedCustomer);
    };

    const onFirstNameChange = value => {
      firstName.onChange(value);

      const updatedSelectedCustomer = {
        ...selectedCustomer,
        firstName: value,
      };
      onCustomerSelected(index, updatedSelectedCustomer);
    };

    const onMiddleNameChange = value => {
      middleName.onChange(value);

      const updatedSelectedCustomer = {
        ...selectedCustomer,
        middleName: value,
      };
      onCustomerSelected(index, updatedSelectedCustomer);
    };

    const onLastNameChange = value => {
      lastName.onChange(value);

      const updatedSelectedCustomer = {
        ...selectedCustomer,
        lastName: value,
      };
      onCustomerSelected(index, updatedSelectedCustomer);
    };

    const onEmailChange = value => {
      email.onChange(value);

      const updatedSelectedCustomer = {
        ...selectedCustomer,
        email: value,
      };
      onCustomerSelected(index, updatedSelectedCustomer);
    };

    const onAdjustCustomerProfileDataChange = profileChangeEvent => {
      const { checked: value } = profileChangeEvent.target;
      const updatedSelectedCustomer = {
        ...selectedCustomer,
        adjustProfileData: value,
      };

      adjustProfileData.onChange(value);
      onCustomerSelected(index, updatedSelectedCustomer);
    };

    const onAssociateOrganizationChange = organizationChangeEvent => {
      const { checked: value } = organizationChangeEvent.target;
      const updatedSelectedCustomer = {
        ...selectedCustomer,
        associateOrganization: value,
      };

      associateOrganization.onChange(value);
      onCustomerSelected(index, updatedSelectedCustomer);
    };

    const onPhoneNumberChange = async value => {
      number.onChange(value);
      const updatedSelectedCustomer = {
        ...selectedCustomer,
        splitPrimaryPhoneNumber: {
          ...selectedCustomer.splitPrimaryPhoneNumber,
          number: value,
        },
      };
      await onCustomerSelected(index, updatedSelectedCustomer);
      if (countryCode.value) {
        this.onPhoneOrCountryChange(countryCode.value[1], value);
      }
    };

    const onPhoneCountryChange = async input => {
      const countryCodeArray = this.props.countryCodes.find(
        countryDetails => countryDetails[1] === input.value,
      );
      countryCode.onChange(countryCodeArray);
      const updatedSelectedCustomer = {
        ...selectedCustomer,
        splitPrimaryPhoneNumber: {
          ...selectedCustomer.splitPrimaryPhoneNumber,
          countryCode: countryCodeArray,
        },
      };
      await onCustomerSelected(index, updatedSelectedCustomer);
      if (number.value) {
        this.onPhoneOrCountryChange(input.value, number.value);
      }
    };

    const onStreetChange = value => {
      street.onChange(value);

      const updatedSelectedCustomer = {
        ...selectedCustomer,
        residenceAddress: { ...selectedCustomer.residenceAddress, street: value },
        address: { ...selectedCustomer.address, street: value },
      };
      onCustomerSelected(index, updatedSelectedCustomer);
    };

    const onCityChange = value => {
      city.onChange(value);

      const updatedSelectedCustomer = {
        ...selectedCustomer,
        residenceAddress: { ...selectedCustomer.residenceAddress, city: value },
        address: { ...selectedCustomer.address, city: value },
      };
      onCustomerSelected(index, updatedSelectedCustomer);
    };

    const onZipChange = value => {
      zip.onChange(value);

      const updatedSelectedCustomer = {
        ...selectedCustomer,
        residenceAddress: { ...selectedCustomer.residenceAddress, zip: value },
        address: { ...selectedCustomer.address, zip: value },
      };
      onCustomerSelected(index, updatedSelectedCustomer);
    };

    const onStateChange = value => {
      state.onChange(value);

      const updatedSelectedCustomer = {
        ...selectedCustomer,
        residenceAddress: { ...selectedCustomer.residenceAddress, state: value },
        address: { ...selectedCustomer.address, state: value },
      };
      onCustomerSelected(index, updatedSelectedCustomer);
    };

    const onCountryChange = value => {
      country.onChange(value);

      const updatedSelectedCustomer = {
        ...selectedCustomer,
        residenceAddress: { ...selectedCustomer.residenceAddress, country: value },
        address: { ...selectedCustomer.address, country: value },
      };
      onCustomerSelected(index, updatedSelectedCustomer);
    };

    const hasUpdateAbility = travelerId => {
      const foundAbility = this.props.travelerUpdateAbilities.find(
        ability => ability.id === travelerId,
      );
      return foundAbility ? foundAbility.currentUserHasUpdateAbility : false;
    };

    return (
      <Card version="v2" size="full" className="col-12 traveler-information">
        {isEditForm ? (
          <div className="traveler-information__header">
            <div className="traveler-information__header-text">
              {I18n.t('components.ibe.traveler_details.updating_general_information')}
            </div>
            <div className="traveler-information__header-sub-text">
              {I18n.t('components.ibe.traveler_details.hints.update_traveler_text')}
            </div>
          </div>
        ) : (
          <div className="traveler-information__header">
            <div className="col-grid col-bleed direction-row align-center traveler-information__header-text">
              <div className="traveler-information__header-text-traveler">
                {I18n.t('components.ibe.traveler_details.traveler', {
                  value: index + 1,
                })}
              </div>
              <div className="traveler-information__header-text-page">
                {I18n.t('components.ibe.traveler_details.create_new_traveler_page', {
                  page: 1,
                  total: flightPresent ? 2 : 1,
                })}
              </div>
            </div>
            <div className="traveler-information__header-sub-text">
              {I18n.t('components.ibe.traveler_details.hints.create_form_text')}
            </div>
          </div>
        )}
        {showHintForTravelerArrangerEmail && (
          <div className="traveler-information__additional">
            <Alert hideClose={true} type="warning" isVisible={true}>
              <I18nText id="ibe.default_travel_arranger_mail.alert_message" />
            </Alert>
          </div>
        )}
        {isAssociatedToOrganization && (
          <div className="traveler-information__additional">
            <CheckBox
              label={I18n.t('components.ibe.traveler_details.hints.associate_to_organization')}
              checked={this.props.fields.associateOrganization.value}
              onChange={onAssociateOrganizationChange}
              size="large"
            />
          </div>
        )}

        <div className="grid traveler-information__container">
          <div className="col-md-4 col-lg-4 col-12 traveler-information__container-field">
            <Input
              {...this.addSpaceBetweenError(firstName)}
              size="tiny"
              label={I18n.t('components.ibe.traveler_details.form.fields.first_name')}
              placeholder={I18n.t('components.ibe.traveler_details.form.placeholders.first_name')}
              disabled={disabled}
              touched={touched}
              required={true}
              onChange={value => onFirstNameChange(value)}
            />
          </div>
          <div className="col-md-4 col-lg-4 col-12 traveler-information__container-field">
            <Input
              {...this.addSpaceBetweenError(middleName)}
              label={I18n.t('components.ibe.traveler_details.form.fields.middle_name')}
              disabled={disabled}
              touched={touched}
              size="tiny"
              placeholder={I18n.t('components.ibe.traveler_details.form.placeholders.middle_name')}
              onBlur={() => {}}
              onChange={value => onMiddleNameChange(value)}
            />
          </div>
          <div className="col-md-4 col-lg-4 col-12 traveler-information__container-field">
            <Input
              {...this.addSpaceBetweenError(lastName)}
              label={I18n.t('components.ibe.traveler_details.form.fields.last_name')}
              placeholder={I18n.t('components.ibe.traveler_details.form.placeholders.last_name')}
              disabled={disabled}
              touched={touched}
              size="tiny"
              required={true}
              onBlur={() => {}}
              onChange={value => onLastNameChange(value)}
            />
          </div>
          {this.renderSalutation({ ...namePrefix, onBlur: () => {} })}
          <div className="col-md-6 col-lg-6 col-12 traveler-information__container-field">
            <DatePickerWithFocusedStateWrapper
              {...birthday}
              label={I18n.t('components.ibe.traveler_details.form.fields.birthday')}
              placeholder={I18n.t('components.ibe.traveler_details.form.placeholders.birthday')}
              id={Math.random()}
              size="tiny"
              fullWidth={true}
              enablePastDates={true}
              disableFutureDates={true}
              date={birthday.value}
              locale={moment().locale()}
              pastYearsCount={100}
              futureYearsCount={0}
              onChange={value => value && onBirthDayChange(moment(value).format('YYYY-MM-DD'))}
              maxDate={moment()}
              disabled={disabled}
              touched={touched}
              required={true}
              onBlur={() => {}}
              hideNavButtons={false}
            />
          </div>
          {index !== 0 && (
            <div className="col-12 traveler-information__container-field">
              <div className="traveler-information__container-field-check-information">
                <CheckBox
                  label={I18n.t(
                    'components.ibe.traveler_details.add_individual_contact_information',
                    { count: index + 1 },
                  )}
                  checked={!!this.state.addIndividualContactInformation}
                  onChange={this.setAddIndividualContactInformation}
                  size="large"
                />
              </div>
              <div className="traveler-information__container-field-check-hint">
                <I18nText
                  id="ibe.traveler_details.hints.add_individual_contact_information"
                  returnStringOnly={true}
                />
              </div>
            </div>
          )}

          {showContactInformation && (
            <Fragment>
              <div className="col-md-6 col-lg-6 col-12 traveler-information__container-field">
                <Input
                  {...travelerCartEmail}
                  type="email"
                  label={I18n.t('components.ibe.traveler_details.form.fields.email')}
                  placeholder={I18n.t('components.ibe.traveler_details.form.placeholders.email')}
                  disabled={this.props.fields.isTravelArrangerEmailUsed.value || disabled}
                  touched={touched}
                  size="tiny"
                  required={this.context.laymanMode}
                  onBlur={() => {
                    this.props.travelerEmailValidate();
                  }}
                  onChange={value => onEmailChange(value)}
                />
                {this.renderUserTravelArrangerEmailCheckbox()}
              </div>
              <div className="col-md-6 col-lg-6 col-12 traveler-information__container-field">
                <PhoneNumber
                  options={this.phoneCountryCodes}
                  size="tiny"
                  label={I18n.t('components.ibe.traveler_details.form.fields.phone')}
                  onChange={input => onPhoneNumberChange(input)}
                  onCountryChange={input => onPhoneCountryChange(input)}
                  inputValue={number.value}
                  disabled={disabled}
                  selectedCountry={
                    countryCode.value &&
                    this.phoneCountryCodes.find(code => code.value === countryCode.value[1])
                  }
                  error={phone.error}
                  isInputTouched={!!phone.error}
                  onBlur={() => this.props.phoneNumberValidate()}
                  countryPlaceholder="(+41)"
                  numberPlaceHolder={I18n.t(
                    'components.ibe.traveler_details.form.placeholders.phone',
                  )}
                />
              </div>

              <div className="col-md-6 col-lg-6 col-12 traveler-information__container-field">
                <Input
                  {...street}
                  label={I18n.t('components.ibe.traveler_details.form.fields.street')}
                  placeholder={I18n.t('components.ibe.traveler_details.form.placeholders.street')}
                  value={street.value}
                  disabled={disabled}
                  touched={touched}
                  size="tiny"
                  required={true}
                  onBlur={() => {}}
                  onChange={value => onStreetChange(value)}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-12 traveler-information__container-field">
                <Input
                  {...zip}
                  label={I18n.t('components.ibe.traveler_details.form.fields.zip')}
                  placeholder={I18n.t('components.ibe.traveler_details.form.placeholders.zip')}
                  fullWidth={true}
                  value={zip.value}
                  disabled={disabled}
                  touched={touched}
                  size="tiny"
                  required={true}
                  onBlur={() => {}}
                  onChange={value => onZipChange(value)}
                />
              </div>
              <div className="col-md-4 col-lg-4 col-12 traveler-information__container-field">
                <Input
                  {...city}
                  label={I18n.t('components.ibe.traveler_details.form.fields.city')}
                  placeholder={I18n.t('components.ibe.traveler_details.form.placeholders.city')}
                  value={city.value}
                  disabled={disabled}
                  touched={touched}
                  size="tiny"
                  required={true}
                  onBlur={() => {}}
                  onChange={value => onCityChange(value)}
                />
              </div>
              <div className="col-md-4 col-lg-4 col-12 traveler-information__container-field">
                <Input
                  {...state}
                  label={I18n.t('components.ibe.traveler_details.form.fields.state')}
                  placeholder={I18n.t('components.ibe.traveler_details.form.placeholders.state')}
                  value={state.value}
                  disabled={disabled}
                  touched={touched}
                  size="tiny"
                  onBlur={() => {}}
                  onChange={value => onStateChange(value)}
                />
              </div>
              <div className="col-md-4 col-lg-4 col-12 traveler-information__container-field">
                <CountrySelector
                  {...country}
                  title={I18n.t('components.ibe.traveler_details.form.fields.country')}
                  placeholder={I18n.t('components.ibe.traveler_details.form.placeholders.country')}
                  disabled={disabled}
                  required={true}
                  touched={true}
                  isClearable={false}
                  onBlur={() => {}}
                  onChange={value => onCountryChange(value)}
                />
              </div>
              {isEditForm &&
              this.props.fields.id.value !== '' &&
              (this.props.travelerUpdateAbilities.length === 0 ||
                hasUpdateAbility(this.props.fields.id.value)) ? (
                <Fragment>
                  <CheckBox
                    className="col-md-6 col-lg-6 col-12 col-grid direction-row align-center traveler-information__container-field"
                    label={I18n.t('components.ibe.traveler_details.form.fields.update_profile')}
                    checked={this.props.fields.adjustProfileData.value}
                    onChange={onAdjustCustomerProfileDataChange}
                    size="small"
                    disabled={disabled}
                  />
                  <span className="col-12 traveler-information__update-hint">
                    {I18n.t('components.ibe.traveler_details.hints.update_profile')}
                  </span>
                </Fragment>
              ) : null}
            </Fragment>
          )}
        </div>

        <div className="col-grid col-bleed direction-row align-center traveler-information__actions">
          <div className="traveler-information__actions-confirm">
            {flightPresent && !isEditForm ? (
              <Button
                version="v2"
                onClick={this.proceedToTravelInformation}
                size="small"
                type="primary"
                label={I18n.t('components.ibe.traveler_details.buttons.next')}
              />
            ) : (
              <Button
                version="v2"
                onClick={() => this.saveTraveler()}
                size="small"
                type="primary"
                label={
                  isEditForm
                    ? I18n.t('components.ibe.traveler_details.buttons.update')
                    : I18n.t('components.ibe.traveler_details.buttons.create')
                }
              />
            )}
          </div>
          <div className="traveler-information__actions-cancel">
            <Button
              version="v2"
              onClick={() => this.resetTraveler()}
              size="small"
              label={I18n.t('components.ibe.traveler_details.buttons.cancel')}
            />
          </div>
        </div>
      </Card>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onCustomerSelected: (index, query) => dispatch(customerSelected(index, query)),
});

TravelerInformation.defaultProps = {
  editable: false,
  disabled: false,
  touched: true,
  isEditForm: false,
  setNewTravelerFormPage: () => {},
  setSingleTravelerShowPage: () => {},
  refetchCurrentCustomer: () => {},
  travelArrangerEmail: '',
  isTravelArrangerEmailUsed: false,
  travelerUpdateAbilities: [],
};

TravelerInformation.propTypes = {
  index: PropTypes.number.isRequired,
  templateTraveler: PropTypes.shape().isRequired,
  fields: PropTypes.shape().isRequired,
  namePrefixList: PropTypes.arrayOf(PropTypes.shape).isRequired,
  phoneNumberValidate: PropTypes.func.isRequired,
  travelerEmailValidate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  setNewTravelerFormPage: PropTypes.func,
  touched: PropTypes.bool,
  reset: PropTypes.func.isRequired,
  isAssociatedToOrganization: PropTypes.bool.isRequired,
  showHintForTravelerArrangerEmail: PropTypes.bool.isRequired,
  setSingleTravelerShowPage: PropTypes.func,
  isEditForm: PropTypes.bool,
  countryCodes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  flightPresent: PropTypes.bool.isRequired,
  resetTravelerDetails: PropTypes.func.isRequired,
  travelArrangerEmail: PropTypes.string,
  isTravelArrangerEmailUsed: PropTypes.bool,
  setResoOptionEmail: PropTypes.func.isRequired,
  setResoOptionPhone: PropTypes.func.isRequired,
  passEmailToAirline: PropTypes.bool.isRequired,
  passPhoneToAirline: PropTypes.bool.isRequired,
  refetchCurrentCustomer: PropTypes.func,
  editable: PropTypes.bool,
  onCustomerSelected: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.shape({
    residenceAddress: PropTypes.shape({}),
    address: PropTypes.shape({}),
    splitPrimaryPhoneNumber: PropTypes.shape({}),
  }).isRequired,
  travelerUpdateAbilities: PropTypes.arrayOf(PropTypes.shape),
};

TravelerInformation.contextTypes = {
  laymanMode: PropTypes.bool.isRequired,
  travelArrangerDefaultEmail: PropTypes.string,
};

export default connect(null, mapDispatchToProps)(injectIntl(TravelerInformation));
